import { styled, Box } from "@mui/system";

export const LeftAligned = styled(Box)(({theme}) =>`
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
    padding-left: 6vw;
    padding-right: auto;
    ${theme.breakpoints.down('lg')} {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-left: 6px;
        padding-right: 6px;
    }
`);