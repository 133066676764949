import { CircularProgress, styled } from "@mui/material";
import React from 'react';
import { Logo, PageLayoutCenter } from "../../ui/atoms";

const LogoWrapper = styled('div')(({theme}) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4rem;
    ${theme.breakpoints.down('sm')} {
        margin: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
`);

export const LoadingScreen = () => {
    return(
        <PageLayoutCenter bgcolor={theme => theme.palette.background.default}>
            <LogoWrapper>
                <Logo />
            </LogoWrapper>
            <CircularProgress size={60} disableShrink={true}/>
        </PageLayoutCenter>
    )
}