import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { Provider } from 'react-redux';
import store from './app/store';
import { LoadingScreen } from './app/views/loading';
import { queryClient } from './client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './ui/theme';

const App = lazy(() => import('./app/app'));
const localStoragePersistor = createWebStoragePersistor({storage: window.localStorage});
 
persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});



ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Suspense fallback={<LoadingScreen />}>
              <App />
            </Suspense>
          </Provider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
