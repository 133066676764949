import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const ArrowRight = styled(Box)(({ theme }) => `
    width: 0; 
    height: 0; 
    border-top: 0.6rem solid transparent;
    border-bottom: 0.6rem solid transparent;
    border-left: 1.6rem solid;
    border-left-color: ${theme.palette.secondary.main};
    margin-top: 0.4rem;
`);