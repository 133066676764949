import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const PageHeader = styled(Typography)(({ theme }) => `
    color: ${theme.palette.primary.main};
    margin-top: 2rem;
    margin-bottom: 3rem;
    border-bottom: 3px;
    text-align: left;
    ${theme.breakpoints.down('md')} {
        font-size: ${theme.typography.h1.fontSizeSm};
        margin-bottom: 4rem;
        text-align: center;
    }
`);