import { styled } from "@mui/system";
import chackmark from '../../../assets/icons/checkmark.svg';

export const CheckMark = styled('div')`
    background: var(--color-gold-gradient);
    mask-image: url(${chackmark});
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 5rem;
    height: 6rem;
    margin: 0.5rem;
`;