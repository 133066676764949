import { Box, styled } from "@mui/system";

export const PageLayout = styled(Box)`
    height: 100vh;
    padding-left: 10rem;
    padding-right: 10rem;
    @media (max-height: 680px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
`;