import { Box } from "@mui/material";
import { styled } from "@mui/system";
import logoText from '../../../assets/logo_text.svg';

export const LogoText = styled(Box)`
    background: var(--color-gold-gradient);
    mask-image: url(${logoText});
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 28rem;
    height: 3rem;
    margin: 0.5rem;
    @media (max-height: 680px) {
        width: 20rem;
    }
`

export const LogoTextWhite = styled(LogoText)(({ theme }) =>`
    background: ${theme.palette.text.primary};
`);