import { styled, Button} from '@mui/material';

const StyledButton = styled(Button)(({ theme }) =>`
    width: 14em;
    height: 3.5em;
    font-size: 1.1rem;
    font-weight: 100;
    line-height: 1.75rem;

    ${theme.breakpoints.down('sm')} {
        width: 100%;
        font-size: 1rem;
    }
`);

export const ActionButton = ({ children, onClick}) => {
    return(<StyledButton variant='contained' color='secondary' onClick={onClick}>{children}</StyledButton>)
}