import { configureStore, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit'
import actionPageSlice from './features/action-page.slice';
import translationSlice from './features/translation.slice'

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable: () => true
})

export default configureStore({
  reducer: {
    translation: translationSlice,
    actionPage: actionPageSlice,
  },
  middleware: [serializableMiddleware]
});