import { styled } from "@mui/system";
import xmark from '../../../assets/icons/xmark.svg';

export const XMark = styled('div')`
    background: var(--color-gold-gradient);
    mask-image: url(${xmark});
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 5rem;
    height: 6rem;
    margin: 0.5rem;
`;