import { styled, Box } from '@mui/material';

export const LineContainer = styled(Box)(({theme}) => `
    position: absolute;
    left: 0;
    z-index: 1;
`);

export const Line = styled(Box)(({theme}) => `
    height: 2px;
    background-color: ${theme.palette.secondary.light};
    margin-bottom: 3.6rem;
`);