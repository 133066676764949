import sanityClient from "@sanity/client";
import { QueryClient } from 'react-query';

export const sanity = sanityClient({
  projectId: "6sz7k2gg",
  dataset: "production",
  apiVersion: '2021-10-21',
  useCdn: true
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});