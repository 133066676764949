import { createSlice } from '@reduxjs/toolkit';

export const actionPageSlice = createSlice({
  name: 'action-page',
  initialState: {
      isOpen: false,
      content: ''
  },
  reducers: {
    setActionPage: (state, action) => {
        state.isOpen = action.payload.isOpen;
        state.content = state.isOpen ? action.payload.content : state.content;
    }
  }
})

export const { setActionPage } = actionPageSlice.actions;
export default actionPageSlice.reducer;