import { styled, Box } from "@mui/system";

export const HorizontalSplitLayout = styled(Box)(({ theme }) =>`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    ${theme.breakpoints.down('lg')} {
        grid-auto-flow: column;
        grid-template-columns: 1fr;
        grid-template-rows: 1.2fr 0.8fr;
    }
`);