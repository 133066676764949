import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F3A949',
      dark: '#B4662A'
    },
    secondary: {
      main: '#3B8A7F'
    },
    info: {
      main: '#5E9DB2'
    },
    text: {
      primary: '#fff'
    },
    background: {
      default: '#292B33',
      light: '#F4F4F4',
      dark: '#040303',
      paper: '#545454'
    }
  },
  size: {
    actionbar: {
      height: '64px'
    }
  },
  typography: {
    fontSize: 12,
    fontWeightLight: 100,
    button: {
      // fontFamily: 'Roboto Slab',
    },
    handwritten: {
      fontFamily: 'Marck Script'
    }
  },
  props: {
    MuiAppBar: {
      color: 'default',
    },
    MuiTooltip: {
      arrow: true,
    },
  },
});

theme.typography.h1 = {
  fontFamily: 'Alegreya',
  fontSize: '3.4rem',
  lineHeight: 1,
  [theme.breakpoints.down('xl')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  }
}

theme.typography.h2 = {
  fontFamily: 'Alegreya',
  fontSize: '3rem',
  fontWeight: '60',
  lineHeight: 1,
  [theme.breakpoints.down('xl')]: {
    fontSize: '2.4rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.1rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  }
}

theme.typography.h4.fontSize = '1.82rem';
theme.typography.h4[theme.breakpoints.down('md')] = { fontSize: '1.6rem'};
theme.typography.h4[theme.breakpoints.down('sm')] = { fontSize: '1.4rem'};

theme.typography.h5.fontSize = '1.28rem';
theme.typography.h5[theme.breakpoints.down('md')] = { fontSize: '1.1rem'};
theme.typography.h5[theme.breakpoints.down('sm')] = { fontSize: '1rem'};

theme.typography.body1.fontSize = '1.1rem';

export { theme };