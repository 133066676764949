import { createSlice } from '@reduxjs/toolkit'

// Services Icons
import toothBrace from '../../assets/icons/tooth_brace.png';
import toothImplant from '../../assets/icons/tooth_implant.png';
import toothShine from '../../assets/icons/tooth_shine.png';
import toothCross from '../../assets/icons/tooth_cross.png';
import toothGum from '../../assets/icons/tooth_gum.png';
import toothExam from '../../assets/icons/tooth_exam.png';

// Services images
import dentalExamImage from '../../assets/images/services/exam-min.jpg';
import orthodonticsImage from '../../assets/images/services/orthodontics-min.jpg';
import implantologyImage from '../../assets/images/services/implantology-min.jpg';
import aestheticImage from '../../assets/images/services/aesthetic-min.JPG';
import emergencyImage from '../../assets/images/services/emergency-min.JPG';
import otherImage from '../../assets/images/services/other-min.jpg';

// Before - After
import firstBefore from '../../assets/images/gallery/1-before.jpeg';
import firstAfter from '../../assets/images/gallery/1-after.jpeg';
import secondBefore from '../../assets/images/gallery/2-before.jpg';
import secondAfter from '../../assets/images/gallery/2-after.jpg';

// About us
import experience from '../../assets/images/aboutus/waiting-room-min.jpeg';
import firstVisit from '../../assets/images/aboutus/flower-min.JPG';
import solutions from '../../assets/images/aboutus/side-min.jpeg';
import technology from '../../assets/images/aboutus/front-min.jpeg';

import sunshine from '../../assets/images/unordered/sunshine-edit-min.jpg';
import blogFirstVisit from '../../assets/images/unordered/first-visit-min.jpg';

export const locales = [
  { code: 'en', title: 'English' },
  { code: 'mk-MK', title: 'Macedonian' } 
];

const initialState = {
  locale: 'en',
  ui: {
    mainMenu: [
      { key: '', text: 'Home'},
      { key: 'meet-your-dentists', text: 'Meet Your Dentists'},
      { key: 'the-difference-we-make', text: 'The Difference We Make'},
      { key: 'smile-gallery', text: 'Smile Gallery'},
      { key: 'our-services', text: 'Our Services'},
      { key: 'doctors-advice', text: 'Doctor\'s Advice'}
    ],
    footer: {
      newsletter: {
        title: 'Stay in touch',
        text: 'Get informed with the latest news from our polyclinic',
        emailLabel: 'Enter your e-mail'
      },
      contact: {
        title: 'Contact us',
        phone: '+389 70 37 99 99 or +389 2 3 133 533',
        email: 'contact@smiledesign.mk'
      },
      workHours: {
        title: 'Office hours',
        days: 'Monday - Friday',
        hours: '10:00 - 19:00'
      },
      navigation: {
        title: 'Navigation',
        items: [
          { text: 'FAQ', route: '' },
          { text: 'Our services', route: 'our-services' },
          { text: 'Blog', route: 'doctors-advice' },
          { text: 'location', route: 'location' }
        ]
      },
      socialMedia: [
        { icon: '', url: '' }
      ]
    },
    forms: {
      contact: {
        title: 'Book your appointment easy.',
        actionButton: 'Submit',
        successTitle: 'Thank you.',
        successMessage: 'We\'ve received your request. We will contact you soon. \n In the meantime feel free to take a look at some great tips from our doctors.',
        failTitle: "Something went wrong.",
        failMessage: "We are sorry, but we were not able to process your request, please try again later",
        successActionButton: 'Read More',
        fields: {
          name: 'Name and surname',
          email: 'Your email address',
          phone: 'Your phone number',
          description: 'Describe your problem',
          appointmentWith: 'Make an appointment with',
          appointmentDate: 'Appointment date'
        }
      }
    }
  },
  pages: {
    home: {
      sections: {
        frontPage: {
          actionButton: 'Book Now',
          contact: {
            title: 'Or call us',
            phone: '+389 70 37 99 99'
          }
        },
        location: {
          title: { 
            row1: 'Feel Great', 
            row2: 'About your dental experience' 
          },
          avatarUrl: sunshine,
          points: [
            {
              title: 'Safety first',
              text: 'We care about your well-being. Providing top level hygiene, we make sure you enjoy your visit during your stay.'
            },
            {
              title: 'Cozy ambient',
              text: 'Forget the image of a sterile waiting room and white coats. Feel like home in our modern studio.'
            },
            {
              title: 'Full scale dental services',
              text: 'From diagnostics and X - ray imaging to a complete facial and smile makeover.Completely painless.'
            }
          ]
        },
        team: {
          title: {
            row1: 'With your',
            row2: 'TOP RATED',
            row3: 'Dental team',
          },
          actionButton: 'Meet the team',
          bullets: [
            'Certified specialists with over a decade of experience in the field'
          ]
        },
        services: {
          title: { 
            row1: {
              text: 'Design the',
              highlight:'Smile'
            },
            row2: 'You DESIRE',
          },
          actionButton: 'More services',
          content: [
            { icon: toothBrace, text: 'orthodontics', alt:'Icon of a tooth with braces'},
            { icon: toothImplant, text: 'Oral surgery and Implantology', alt: 'Icon of an implant'},
            { icon: toothShine, text: 'aesthetic dentistry', alt: 'Icon of a sparkling tooth'},
            { icon: toothCross, text: 'emergency', alt: 'Icon of a tooth with first aid cross'},
            { icon: toothGum, text: 'periodontology', alt: 'Icon of a tooth and gums'},
            { icon: toothExam, text: 'dental exam', alt: 'Icon of a tooth under magnifying glass'}
          ]
        },
        reviews: {
          title: {
            row1: 'Why',
            row2: 'PEOPLE LOVE'
          },
          actionButton: 'Leave a review'
        },
        blog: {
          title: {
            row1: 'YOUR DOCTOR\'s',
            row2: 'Advice'
          },
          actionButton: 'Read More',
          points: [
            "Take care of your smile",
            "Must read advices",
            "Smile makeover need to know"
          ]
        }
      }
    },
    services: {
      title: 'Our Services',
      actionButton: 'Book now',
      sections: {
        dentalExam: {
          title: 'Dental Exam',
          image: dentalExamImage,
          alt: 'Side shot of teeth and mouth',
          text: 'A comprehensive wellness visit with a comfortable cleaning (in the absence of periodontal disease), x-rays, and a personalized exam.'
        },
        orthodontics: {
          title: 'Orthodontics',
          alt: 'Front shot of teeth with braces biting jelly',
          image: orthodonticsImage,
          text: 'A new care-free, can’t-see straightening technology that goes behind your teeth , in front of your teeth or clear aligners Removable appliances for kids and adults'
        },
        implantology: {
          title: 'Oral surgery and Implantology',
          alt: 'Shot of an open mouth',
          image: implantologyImage,
          text: 'Beautiful, long-lasting implants that transform your bite and your smile.'
        },
        aesthetic: {
          title: 'Aesthetic dentistry ',
          alt: 'Image of a smile',
          image: aestheticImage,
          text: 'A gorgeous new smile with digitally-designed, expertly-crafted porcelain veneers.'
        },
        emergency: {
          title: 'Emergency procedures',
          alt: 'Side shot of a mouth with water droplets in the background',
          image: emergencyImage,
          text: 'Chipped tooth? Filling came out? Toothache? Swelling? We’ll see you ASAP.'
        },
        other: {
          title: 'Other services',
          image: otherImage,
          alt: 'Frontal shot of a mouth biting jelly',
          text: 'We offer a full range of preventative, restorative, and cosmetic services.',
          servicesList: [
            'Facial aesthetic',
            'Periodontology',
            'Pediatric dentistry',
            'Root canal treatment',
            'Extractions',
            'Fillings',
            'Crowns',
            'Bridges',
            'Veneers',
            'Teeth whitening'
          ]
        }
      }
    },
    aboutus: {
      title: 'The Difference We Make',
      sections: {
        experience: {
          title: 'Enhance your experience',
          text: 'We care about your well being. Forget about dental anxiety, sterile waiting room, the white coats. You are important and so is your positive experience in our polyclinic. A beautiful, contemporary dental studio with a soothing environment and cozy ambient is just the place to relax, freshen up and wait for your appointment.',
          image: experience
        },
        firstVisit: {
          title: 'Your first visit',
          text: 'It\'s dentistry done differently and you\'ll feel it the moment you\'ll walk through the door. We\'d love to get to know you - relaxed, face-to-face, with no judgement. Let\'s chat about your dreams and concerns about the smile you desire. After a thorough dental evaluation, exam and X-ray imaging we\'ll create a treatment plan together. Let us handle all the details, you deserve the best personalized solutions',
          image: firstVisit
        },
        solutions: {
          title: 'Personalized dental solutions',
          text: 'A smile is a universal language but each one tells an individual story. We will design the smile you desire and offer a unique, personalized solution as a treatment plan. Transparency and trust between a doctor and a patient is crucial - you get to have an individual dental portfolio with complete treatment history available for you to see at any time.',
          image: solutions
        },
        technology: {
          title: 'State-of-the-art technology',
          text: 'Utilizing the latest equipment and advanced practices in contemporary dentistry we ensure that you get the best quality, aesthetic dental solution in real time. From diagnostics to a complete facial and smile makeover',
          image: technology
        },
        team: {
          title: 'The \'A\' team',
          text: '\'A\' for the amazing, acclaimed, amicable, assertive and attentive staff we provide. Led by renowned doctors specialists the team is here as your friends, confidants and doctors - the dental family you needed.',
          image: undefined
        }
      }
    },
    gallery: {
      title: 'Smile Gallery',
      images: [
        { before: firstBefore, after: firstAfter},
        { before: secondBefore, after: secondAfter},
        { before: firstBefore, after: firstAfter},
        { before: secondBefore, after: secondAfter}
      ]
    },
    team: {
      title: 'Meet Your Dentists'
    },
    blog: {
      title: 'Doctor\'s Advice',
      articles: [
        {
          id: 'article_0_your-first-visit',
          title: 'Your First Visit',
          titleImage: blogFirstVisit,
          date: '28/3/2022',
          author: 'Smile & Design team',
          sections: [
            {
              subtitle: 'Dental anxiety',
              text: `75% of the adult world population is affected by dental anxiety - fear of going to the dentist. This statistics wouldn’t be relevant if it didn’t contribute to a severe decrease in visits and appointments made with the dentist, resulting in very late, complex and expensive treatments.
                    The fear of experiencing pain in certain procedures and past negative dental experiences are the top reasons for dental fear. Furthermore, the sounds, smells and looks of a dental office heighten fear response and people end up feeling too helpless and embarrassed to make an appointment. 
                    Modern dentistry inclines toward positive and gentle experience of patients to break the dental taboo and create a safe and welcoming environment for them.
                    `,
              image: undefined
            },
            {
              subtitle: 'Welcome',
              text: `It’s dentistry differently, and you’ll know it the moment you’ll walk through the door. You’ll be entering a beautiful and contemporary studio with cozy ambient, a place to relax and freshen up while you wait to be proceeded. 
                    The receptionist will serve you a list of information required from you regarding your general wellbeing and oral health so we can create an individual dental file for you. Each patient is an individual human being, thus having as much information as possible about your health and daily habits is essential for accurate diagnosis and treatment plan that is best for you.`,
              image: undefined
            },
            {
              subtitle: 'Let\'s talk',
              text: `How are you? What are your fears? Your concerns? Your dreams about the smile you desire?
                    We would love to hear about your lifestyle and personality. When it comes to designing the smile of your dreams, communication and trust between a doctor and a patient is crucial. That way you can get to know us and express your opinions and concerns about every procedure and we’ll take in consideration every desire you have, guide you through the process  and design your unique smile.
                    `,
              image: undefined
            },
            {
              subtitle: 'Start from the beginning',
              text: `The technical part of your first visit begins with assessment of the current condition of your oral health. We’ll start with an intraoral exam and an X-Ray image and together with the information you’ve given us we’ll create a treatment plan . Again, communication is vital, so the treatment plan, time frame and costs will be adjusted to your wishes and timely and financial possibilities. 
                  We’ll handle the details: clear what your insurance covers, offer a variety of payment options for you and after an initial mutual agreement we are good to go.
                  `,
              image: undefined
            },
            {
              subtitle: 'Your experience matters',
              text: `Sometimes, just making a dental appointment is stressful enough. Our goal is to create an environment that you’ll be relaxed, comfortable and satisfied with, so that you’ll schedule your next appointment without a doubt. Your experience matters and we’ll make sure that we earn your trust and design a radiating smile. 

                    You can schedule your appointment by making a call to our office on +389 70 37 99 99 or 
                    +389 2 3 133 533
                  Or by using our contact form on our website.
                  `,
              image: undefined
            }
          ]
        }
      ]
    },
    location: {
      title: 'Our location',
      text: [ 
        'Located in the center of the city of Skopje, RNM, right behind TC Bunjakovec.',
        'Whether you are walking, using public transport or your own vehicle, our polyclinic is accessible from each part of the city, yet hidden from the urban noise and traffic.'
      ]
    }
  }
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState: initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    }
  }
})

export const { setLocale } = translationSlice.actions;
export default translationSlice.reducer;